import moment from 'moment'

export function checkDealSchedule (deal, storeSchedule = null, requestedTime = null) {
  requestedTime = requestedTime ? moment.unix(requestedTime).second(0) : null
  const currentTime = moment()
  let startTime
  if (deal.start_date) {
    const startDate = moment(deal.start_date)
    startTime = startDate
    if (deal.start_hours) {
      const timeArr = deal.start_hours.split(':')
      startTime = startTime.add({ hours: timeArr[0], minutes: timeArr[1], seconds: timeArr[2] })
    }
  }
  if (startTime && currentTime.isBefore(startTime)) {
    return null
  }
  let endTime
  if (deal.end_date) {
    const endDate = moment(deal.end_date)
    endTime = endDate
    if (deal.end_hours) {
      const timeArr = deal.end_hours.split(':')
      endTime = endTime.add({ hours: timeArr[0], minutes: timeArr[1], seconds: timeArr[2] })
    }
  }
  if (endTime && currentTime.isAfter(endTime)) {
    return null
  }

  const getStorePreviousDayTime = (time, storeSchedule) => {
    // This returns the store previous day timings based on the time passed to it.
    // The time passed to it can be current time or requested time as of now.
    const previousDay = time.clone().subtract(1, 'days').format('dddd').toLowerCase()
    if (storeSchedule && storeSchedule[previousDay]) {
      const timeArr = storeSchedule[previousDay].split(',').map(time => time.trim())
      if (timeArr.length === 2) {
        const startTime = timeArr[0].split(':').map(time => time.trim())
        const endTime = timeArr[1].split(':').map(time => time.trim())
        const previousStartTime = time.clone().subtract(1, 'days').set({ hours: startTime[0], minutes: startTime[1], seconds: '00' })
        const previousEndTime = time.clone().subtract(1, 'days').set({ hours: endTime[0], minutes: endTime[1], seconds: '00' })
        if (previousEndTime.isBefore(previousStartTime)) {
          previousEndTime.add(1, 'days')
        }
        return { startTime: previousStartTime, endTime: previousEndTime }
      }
    }
    return null
  }
  if (requestedTime && requestedTime.isAfter(endTime)) {
    return null
  }
  if (deal.settings.days_available && deal.settings.days_available.length) {

    if (requestedTime) {
      // Checking if deal is available on requested time (probably user is on logistics page).
      const storeTime = getStorePreviousDayTime(requestedTime, storeSchedule)
      if (!storeTime && !deal.settings.days_available.map(day => day.toLowerCase()).includes(requestedTime.format('dddd').toLowerCase())) {
        // If the store previous day timing is null then we rely only on days.
        // If deal is available for the day.
        return null
      }
      if (storeTime && !deal.settings.days_available.map(day => day.toLowerCase()).includes(requestedTime.format('dddd').toLowerCase()) &&
      requestedTime.isAfter(storeTime.endTime)) {
        return null
      }
    }

    const storeTime = getStorePreviousDayTime(currentTime, storeSchedule)
    if (!storeTime && !deal.settings.days_available.map(day => day.toLowerCase()).includes(currentTime.format('dddd').toLowerCase())) {
      // If the store previous day timing is null then we rely only on days.
      // If deal is available for the day.
      return null
    }
    if (storeTime && !deal.settings.days_available.map(day => day.toLowerCase()).includes(currentTime.format('dddd').toLowerCase()) &&
    currentTime.isAfter(storeTime.endTime)) {
      return null
    }
  }
  return deal
}
  