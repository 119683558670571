/**
 *
 * Module for ingredients
 * ==========================================================
 * Compiles the grouped list of ingredients
 * Handles the selection page
 * Check limits (maximum and minimum added allowed toppings)
 *
 */

import { ingredientLimit, ingredientFreeSwaps } from './const.js'

const processedSelections = {
  selection: {},
  minimumReached: false,
  maximumReached: false
}

import slug from 'slug'

export function compileIngredientGroups (items, ingredientGroups) { // format the groups to be used into the frontend pizza customization
  return ingredientGroups.map(group => {
    let noItem = false
    const ingredients = group.ingredients.map(item => {
      const ingredient = items.filter(i => i.id === item.id)[0]
      if (ingredient) {
        if (slug(ingredient.name).includes('no-')) {
          noItem = ingredient
        }
        return {
          id: ingredient.id,
          name: ingredient.name,
          tags: ingredient.tags,
          sizes: ingredient.sizes,
          slug: slug(ingredient.name)
        }
      }
    }).filter(item => typeof item !== 'undefined').sort((a, b) => {
      if (slug(a.name).includes('no-') || (slug(b.name).includes('no-'))) {
        return 0
      }

      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }

      return 0
    })

    let prices = []
    try {
      prices = ingredients[0].sizes.map(size => {
        return {
          price: size.price,
          size: size.size
        }
      })
    } catch (e) {}

    const ingredientIds = ingredients.map(item => item.id)
    return {
      ingredients,
      ingredientIds,
      name: group.name,
      slug: slug(group.name),
      noItem,
      prices
    }
  }).filter(group => group.ingredients.length > 0)
}

export function getBases (ingredientGroups) { // get all the bases
  const bases = ingredientGroups.filter(group => group.slug === 'base')
  if (bases) {
    return bases[0] ? bases[0].ingredients : []
  }
  return []
}

export function getToppings (ingredientGroups) { // get all the toppings
  return ingredientGroups.filter(group => group.slug !== 'base')
}

export function getPreSelectedToppings (ingredientGroups, productToppings) { // get the preselection for the specific product
  const selectedToppings = {}
  const ingredientIDs = ingredientGroups.map(group => group.ingredientIds).flat()
  ingredientGroups.map(group => group.ingredients).flat().forEach((item) => {
    if (ingredientIDs.includes(item.id)) {
      selectedToppings[item.id] = false
    }
  })
  productToppings.filter(productTopping => ingredientIDs.includes(productTopping.id)).forEach((productTopping) => {
    selectedToppings[productTopping.id] = productTopping.settings.quantity === 'single' ? 1 : 2
  })

  return selectedToppings
}

export function processIngredientSelection (ingredientGroups, currentSelection, previousSelection, selectedIngredient, value, product) { // handle the flow
  let selection = processIngredientRemoval(ingredientGroups, currentSelection, previousSelection, selectedIngredient, value, product)
  selection = processIngredientAddition(ingredientGroups, selection, product)
  return selection
}

export function processIngredientRemoval (ingredientGroups, currentSelection, previousSelection, selectedIngredient, value, product) { // handle the ingredient removals
  const group = ingredientGroups.filter(group => group.ingredientIds.includes(selectedIngredient.id))[0]
  const selectedIds = Object.entries(currentSelection).filter(item => item[1] !== false).map(item => parseInt(item[0]))
  const ingredientIDs = ingredientGroups.map(group => group.ingredientIds).flat()
  const predefined = product.items.filter(item => ingredientIDs.includes(item.id)).map(item => item.id)

  let addedNoItem = false

  if (value === false) { // limit removals to the maximum amount swaps
    const swaps = predefined.filter(item => !selectedIds.includes(item))
    if (group.noItem) {
      if (group.ingredientIds.filter(id => selectedIds.includes(id)).length === 0) {
        currentSelection[group.noItem.id] = 0
        addedNoItem = true
      }
      if (group.noItem.id === selectedIngredient.id) {
        predefined.filter(id => group.ingredientIds.includes(id)).forEach(id => {
          currentSelection[id] = 1
        })
        currentSelection[group.noItem.id] = false
      }
    }

    if (swaps.length > ingredientFreeSwaps) {
      return Object.assign({}, processedSelections, { selection: previousSelection, minimumReached: true })
    }
  }
  if (value === 0) { // the 'no-' ingredient was added, remove others
    const removeIngredients = ingredientGroups.filter(group =>
      group.ingredientIds.includes(selectedIngredient.id))[0].ingredientIds.filter(id => selectedIngredient.id !== id)
    removeIngredients.forEach(id => {
      currentSelection[id] = false
    })
    return Object.assign({}, processedSelections, { selection: currentSelection })
  } else { // make sure that the 'no-' ingredient is not selected
    if (!addedNoItem) {
      group.ingredientIds.forEach((id) => {
        if (currentSelection[id] === 0) {
          currentSelection[id] = false // unselect the 'no-' ingredient
        }
      })
      // Check if ingredient is sauce
      // Removed other ingredient when it's sauce
      const sauceGroup = ingredientGroups.find(group => group.name === 'Sauce')
      if (sauceGroup) {
        const isSauceIngredient = sauceGroup.ingredientIds.includes(selectedIngredient.id)
        if (isSauceIngredient) {
          const removeIngredients = ingredientGroups.filter(group =>
            group.ingredientIds.includes(selectedIngredient.id))[0].ingredientIds.filter(id => selectedIngredient.id !== id)
          removeIngredients.forEach(id => {
            currentSelection[id] = false
          })
        }
      }
      const drizzledSauceGroup = ingredientGroups.find(group => group.name === 'Drizzled Sauces')
      if (drizzledSauceGroup) {
        const isSauceIngredient = drizzledSauceGroup.ingredientIds.includes(selectedIngredient.id)
        if (isSauceIngredient) {
          const removeIngredients = ingredientGroups.filter(group =>
            group.ingredientIds.includes(selectedIngredient.id))[0].ingredientIds.filter(id => selectedIngredient.id !== id)
          removeIngredients.forEach(id => {
            currentSelection[id] = false
          })
        }
      }
    }
    return Object.assign({}, processedSelections, { selection: currentSelection })
  }
}

export function processIngredientAddition (ingredientGroups, selection, product) { // check the limits for selection
  const totalIngredients = Object.values(selection.selection).reduce((total, val) => total + (val !== false ? 1 : 0), 0)
  const ingredientIDs = ingredientGroups.map(group => group.ingredientIds).flat()
  const productTotalIngredients = product.items.filter(item => ingredientIDs.includes(item.id)).length

  selection.maximumReached = (productTotalIngredients > ingredientLimit ?  productTotalIngredients : ingredientLimit) <= totalIngredients
  return selection
}

export function sanitizePredefinedIngredients (selection, defaults) { // clean from selection the predefined ingredients
  if (defaults) {
    const sanitized = Object.assign({}, selection)
    defaults.map(item => {
      return {
        id: item.id,
        qty: item.settings.quantity === 'single' ? 1 : 2
      }
    }).forEach(item => {
      if (selection[item.id] && selection[item.id] === item.qty) {
        delete sanitized[item.id]
      }
    })
    return sanitized
  }
  return selection
}

export function checkLimits (currentSelection, previousSelection, product, ingredientGroups) {
  const selectedIds = Object.entries(currentSelection).filter(item => item[1] !== false).map(item => parseInt(item[0]))
  const ingredientIDs = ingredientGroups.map(group => group.ingredientIds).flat()

  const predefined = product.items.map(item => item.id).filter(id => ingredientIDs.includes(id))

  const swaps = predefined.filter(item => !selectedIds.includes(item))
  if (swaps.length > ingredientFreeSwaps) {
    return Object.assign({}, processedSelections, { selection: previousSelection, minimumReached: true })
  }

  const totalIngredients = Object.values(currentSelection).reduce((total, val) => total + (val !== false ? 1 : 0), 0)

  return Object.assign({}, processedSelections, { selection: currentSelection, maximumReached: ingredientLimit <= totalIngredients })
}
